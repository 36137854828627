
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Getters as AuthGetter } from '@/store/enums/AuthEnums';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Breadcrumb from '@/views/new-design/pages/Settings/Assets/components/Breadcrumb.vue';
import UploadModal from '@/views/new-design/pages/Settings/Assets/components/UploadModal.vue';

import {
  Actions as AssetActions,
  Getters as AssetGetters,
  Mutations as AssetMutations,
} from '@/store/enums/AssetEnums';
import { Breadcrumb as BreadcrumbModel } from '@/models/BreadcrumbModel';
import { capitalize } from '@/utils/text';
import { getFileIcon } from '@/utils/fileicons';
import { Folder } from '@/models/FolderModel';
import store from '@/store';
import toasts from '@/utils/toasts';

const initvalBreadcrumbs = [] as unknown as Array<BreadcrumbModel>;

export default defineComponent({
  components: {
    Navigator,
    Breadcrumb,
    UploadModal,
  },
  data: () => ({
    newFolderName: 'New Folder',
    tableLoading: true,
    uploading: false,
    adding_folder: false,
    breadcrumbs: initvalBreadcrumbs,
    folderFiles: [] as Array<File>,
    loadingUpload: false,
    searchValue: '',
  }),
  async mounted() {
    await this.initDirectory();
  },

  computed: {
    ...mapGetters({
      authUser: AuthGetter.GET_AUTH_USER,
      allDirectories: AssetGetters.GET_DIRECTORIES,
      folders: AssetGetters.GET_FOLDERS,
      crumbs: AssetGetters.GET_BREADCRUMBS,
      currentFolder: AssetGetters.GET_CURRENT_FOLDER,
      parentFolder: AssetGetters.GET_PARENT_FOLDER,
    }),

    formattedCrumbs() {
      return this.breadcrumbs;
    },

    dirContents() {
      let items = [] as unknown as Array<Folder>;

      for (const [key, folder] of Object.entries(this.allDirectories)) {
        const directoryItem = folder as unknown as Folder;
        const fileIcon =
          directoryItem.type == 'folder' ? '.folder' : directoryItem.type;

        if (directoryItem.type !== 'folder') {
          const icon = getFileIcon(fileIcon).icon;
          const fileOption = {
            id: directoryItem.id,
            name: directoryItem.name,
            size: directoryItem.size,
            date_modified: directoryItem.date_modified,
            type: directoryItem.type,
            classIcon: icon,
            href: directoryItem.view_link,
          } as any;
          items.push(fileOption);
        } else {
          const icon = getFileIcon(fileIcon).icon;
          const folderOption = {
            id: directoryItem.id,
            name: directoryItem.name,
            size: directoryItem.size,
            date_modified: directoryItem.date_modified,
            type: directoryItem.type,
            classIcon: icon,
          } as any;
          items.push(folderOption);
        }
      }

      return items;
    },
    filteredDirContents() {
      return this.dirContents.filter((item: any) => {
        return Object.values(item).some(
          (value: any) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });
    },
  },

  methods: {
    ...mapActions({
      fetchRootFolders: AssetActions.FETCH_ROOT_FOLDERS,
      fetchFolders: AssetActions.FETCH_FOLDERS,
      createNewFolder: AssetActions.CREATE_NEW_FOLDER,
      deleteFile: AssetActions.DELETE_FILE,
      deleteFolder: AssetActions.DELETE_FOLDER,
      uploadFile: AssetActions.UPLOAD_FILE,
    }),
    addFolder(value) {
      this.adding_folder = value;
    },
    onSelect(value) {
      this.adding_folder = false;
      this.handleFetchFolders(value ? value?.id : '');
    },
    onDelete(value) {
      this.deleteFolder(value.id);
    },
    resetBreadcrumb() {
      this.breadcrumbs = initvalBreadcrumbs;
    },

    async initDirectory() {
      await this.handleFetchFolders();
      await this.routeBreadcrumbs();
    },

    async handleFetchFolders(folder = '') {
      return await this.fetchFolders(folder).then(async (data) => {
        this.crumbs = await data.breadcrumbs;
        this.folders = await data.folders;

        this.routeBreadcrumbs();

        this.tableLoading = false;
        this.defaultFolderName();
      });
    },

    async routeBreadcrumbs() {
      this.breadcrumbs = [] as unknown as Array<BreadcrumbModel>;

      for (const [key, crumb] of Object.entries(await this.crumbs)) {
        const crumbData = crumb as BreadcrumbModel;
        this.breadcrumbs.push({
          id: crumbData.id ? crumbData.id : '',
          text: crumbData.text ? capitalize(crumbData.text) : '',
          url: crumbData?.id
            ? '/settings/assets/' + crumbData.id
            : '/settings/assets',
          current: crumbData.current ? crumbData.current : false,
          parent: crumbData.parent ? crumbData.parent : [],
        });
      }
    },

    base64URLDecode(str) {
      return str + Array(((4 - (str.length % 4)) % 4) + 1).join('=');
    },

    handleSelectCrumb(breadcrumb) {
      this.adding_folder = false;
      this.$router.replace(breadcrumb.url);

      const folderId = breadcrumb.id ?? '';
      store.commit(AssetMutations.SET_CURRENT_FOLDER, folderId);

      this.handleFetchFolders(folderId);
    },

    async handleSaveFolder(folder = '') {
      const payload = {
        currentFolder: this.currentFolder,
        folderName: folder,
      };

      return await this.createNewFolder(payload).then(async (data) => {
        this.adding_folder = false;
        this.handleFetchFolders(this.currentFolder);
      });
    },

    async handleDeleteFolder(folder) {
      return await this.deleteFolder(folder).then(async (data) => {
        this.adding_folder = false;
        this.removeFromObject(folder.id);
      });
    },

    async handleDeleteFile(file = {} as unknown as Folder) {
      const payload = {
        currentFolder: this.currentFolder,
        file: file.id,
      };

      return await this.deleteFile(payload).then(async (data) => {
        this.adding_folder = false;
        this.removeFromObject(file.id);
      });
    },

    handleAddUploadFile(file) {
      this.folderFiles.push(file);
    },
    handleRemoveSupportDocument(file: any) {
      const fileObject: any = file;
      const fileIndex = this.folderFiles.indexOf(fileObject);

      this.folderFiles.splice(fileIndex, 1);
    },
    async handleUploadFiles() {
      this.loadingUpload = true;

      if (this.folderFiles.length == 0) {
        this.loadingUpload = false;
        toasts.error('', 'Please select files to upload');
        return;
      }

      const payload = {
        folderFiles: this.folderFiles,
        currentFolder: await this.currentFolder,
      };

      await this.uploadFile(payload)
        .then(async () => {
          await this.handleFetchFolders(this.currentFolder);

          this.uploading = false;
          this.folderFiles = [];
          this.loadingUpload = false;
        })
        .catch((error) => {
          this.loadingUpload = false;
        });
    },

    defaultFolderName() {
      let pattern = new RegExp(/New Folder+/, 'i');
      const newFolders = this.allDirectories.filter((folder) => {
        return pattern.test(folder.name);
      });

      this.newFolderName =
        newFolders.length > 0
          ? `New Folder (${newFolders.length})`
          : `New Folder`;
    },

    routeToParent() {
      const parentRoute = this.breadcrumbs.filter((crumb) => {
        return crumb.parent == this.parentFolder;
      });
    },

    removeFromObject(id) {
      const objWithIdIndex = this.allDirectories.findIndex(
        (obj) => obj.id === id
      );

      if (objWithIdIndex > -1) {
        this.allDirectories.splice(objWithIdIndex, 1);
      }
    },

    handleOnViewFile(item) {
      window.open(item.href, '_blank', 'noreferrer');
    },
  },
  watch: {
    '$route.params.id': {
      async handler(value) {
        const folder = await value;
        if (typeof folder !== 'undefined' && folder !== '') {
          store.commit(AssetMutations.SET_CURRENT_FOLDER, folder);
          this.handleFetchFolders(folder);
        }
      },
      immediate: true,
      deep: true,
    },
  },
});
