
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import toasts from '@/utils/toasts';

export default defineComponent({
  components: { Modal },
  props: {
    loadState: {
      type: Boolean,
      default: false,
    },
    modelValue: { type: Boolean, default: false },
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {
      modal: {} as HTMLElement,
      fileList: [],
      loadingUpload: false,
      dontShow: false,
    };
  },
  emits: [
    'update:modelValue',
    'addUploadFile',
    'removeUploadFile',
    'uploadFiles',
  ],
  methods: {
    uploadFiles() {
      this.$emit('uploadFiles');
    },
    handleRemove() {
      this.$emit('removeUploadFile', null);
    },
    handleSuccess() {
      (this.$refs.uploader as any).clearFiles();
    },
    onChange(file) {
      let valid = this.validateFile(file);
      if (valid) {
        this.$emit('addUploadFile', file.raw);
      }
    },
    validateFile(file) {
      if (file.raw.size / 1024 / 1024 > 5) {
        toasts.error({}, 'File size can not exceed 5MB!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      return true;
    },
  },
  watch: {
    loadState(value) {
      this.loadingUpload = value;
    },
    modelValue(value) {
      if (value) {
        (this.$refs.uploader as any).clearFiles();
      }
    },
  },
});
