
import { Breadcrumb } from '@/models/BreadcrumbModel';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => {
        return [] as unknown as Array<Breadcrumb>;
      },
    },
  },
  emits: ['onSelectCrumb'],
  methods: {
    selectCrumb(crumb: unknown | Breadcrumb) {
      this.$emit('onSelectCrumb', crumb);
    },
  },
});
