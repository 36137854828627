export const getFileIcon = (name = '') => {
  const ext = name?.split('.').pop() as string;
  const filetypes = {
    png: { icon: 'bi bi-filetype-png' },
    jpg: { icon: 'bi bi-filetype-jpg' },
    jpeg: { icon: 'bi bi-filetype-jpg' },
    gif: { icon: 'bi bi-filetype-gif' },
    pdf: { icon: 'bi bi-filetype-pdf' },
    docx: { icon: 'bi bi-filetype-docx' },
    doc: { icon: 'bi bi-filetype-doc' },
    txt: { icon: 'bi bi-filetype-txt' },
    xlsx: { icon: 'bi bi-filetype-xlsx' },
    xls: { icon: 'bi bi-filetype-xls' },
    svg: { icon: 'bi bi-filetype-svg' },
    pptx: { icon: 'bi bi-filetype-pptx' },
    ppt: { icon: 'bi bi-filetype-ppt' },
    other: { icon: 'bi bi-file-earmark-fill' },
    folder: { icon: 'fas fa-folder folder-icon' },
  };

  if (Object.keys(filetypes).includes(ext)) {
    return filetypes[ext];
  }

  return filetypes.other;
};
